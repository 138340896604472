@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
@import "../node_modules/bootstrap/scss/functions";
// 2. Include any default variable overrides here
// Default variable overrides
// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api";
// 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/tooltip";
//@import "node_modules/bootstrap/scss/_pagination.scss";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/progress";

@import '../node_modules/ngx-toastr/toastr';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.action-column {
  width: 15% !important;
}

.icon-blue {
  color: var(--text-action-high-blue-france);
}

.fr-text-center {
  text-align: center !important;
}

.fr-text-right {
  text-align: right !important;
}

.fr-input-group {
  display: inline-flex;
  width: 100%;
  align-content: flex-start;
  flex-wrap: wrap;
}

.fr-select-group {
  display: grid;
  width: 100%;
  align-content: flex-start;
  flex-wrap: wrap;
}

.fr-form-group {
  display: inline-flex;
  width: 100%;
  align-content: flex-start;
  flex-wrap: wrap;
}

.input-group {
  display: inline-flex;
  width: 100%;
}

.fr-input-wrap {
  display: inline-flex !important;
  width: 100%;
}

.ng-select.custom .ng-select-container {
  background-color: var(--background-contrast-grey);
  box-shadow: inset 0 -2px 0 0 var(--border-plain-grey);
  border-radius: 0.25rem 0.25rem 0 0;
  border: 0;
  min-height: 40px;

}

.ng-select.custom .ng-value-container {
  .ng-value {
    white-space: normal !important;
  }
}
.ng-dropdown-panel .ng-dropdown-panel-items {
  background-color: #0a76f6;
}
.ng-select.custom.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: var(--blue-france-sun-113-625);
  color: white;
  border-radius: 10px;
  padding: 3px;
}

.ng-select.custom.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: none;
  background-color: transparent;
}

.ng-select.inputOk .ng-select-container {
  box-shadow: inset 0 -2px 0 0 var(--border-plain-success) !important;
}

.ng-select.inputNonOk .ng-select-container {
  box-shadow: inset 0 -2px 0 0 var(--border-plain-error) !important;
}

.input-inline {
  max-width: 6rem;
  display: inline !important;
  padding: 0.2rem !important;
}

.ng-select.custom.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  color: black;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: black;
}

th[sortTable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;

  &:after {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11.95 7.95l-1.414 1.414L8 6.828 8 20H6V6.828L3.465 9.364 2.05 7.95 7 3l4.95 4.95zm10 8.1L17 21l-4.95-4.95 1.414-1.414 2.537 2.536L16 4h2v13.172l2.536-2.536 1.414 1.414z' fill='rgba(0,0,145,1)'/%3E%3C/svg%3E") no-repeat;
  }

  &.desc:after {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13 16.172l5.364-5.364 1.414 1.414L12 20l-7.778-7.778 1.414-1.414L11 16.172V4h2v12.172z' fill='rgba(0,0,145,1)'/%3E%3C/svg%3E");
  }

  &.asc:after {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13 7.828V20h-2V7.828l-5.364 5.364-1.414-1.414L12 4l7.778 7.778-1.414 1.414L13 7.828z' fill='rgba(0,0,145,1)'/%3E%3C/svg%3E");
  }

  &:after, &.desc:after, &.asc:after {
    content: "";
    display: block;
    background-size: 18px;
    width: 18px;
    height: 18px;
    margin-top: 3px;
    float: right;
  }
}

.progress-bar {
  background-color: var(--background-active-blue-france) !important;
}

.trait_dessus {
  border-top: 1px solid #E5E5E5;
  width: 100%;
}

textarea {
  text-align: justify;
  white-space: normal;
}

.dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

input[type=radio]:disabled, input[type=radio]:disabled + label {
    cursor: pointer !important;
}

.fr-fieldset:disabled .fr-label, .fr-fieldset:disabled .fr-fieldset__legend {
    color: black !important;
}

.fr-header__menu-links-custom::after {
    box-shadow: inset 0 1px 0 0 #ddd;
  }

  .fr-header__menu-links-custom .fr-btn {
    background-color: transparent;
    color: #000091;
    box-shadow: none;
  }

  .fr-header__menu-links-custom .fr-btn:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .fr-header__menu-links-custom .fr-btn:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .fr-header__menu-links-custom .fr-btn:disabled,
  .fr-header__menu-links-custom a.fr-btn:not([href]) {
    color: #929292;
    background-color: transparent;
  }

  .fr-header__menu-links-custom .fr-btn:disabled:hover,
  .fr-header__menu-links-custom a.fr-btn:not([href]):hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .fr-header__menu-links-custom .fr-btn:disabled:active,
  .fr-header__menu-links-custom a.fr-btn:not([href]):active {
    background-color: rgba(0, 0, 0, 0.1);
  }

.fr-header__menu-links-custom .fr-link {
    background-color: transparent;
    color: #000091;
    box-shadow: inset 0 -1px 0 0 #ddd;
  }

  .fr-header__menu-links-custom .fr-link:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .fr-header__menu-links-custom .fr-link:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .fr-header__menu-links-custom .fr-link:disabled,
  .fr-header__menu-links-custom a.fr-link:not([href]) {
    color: #929292;
    background-color: transparent;
  }

  .fr-header__menu-links-custom .fr-link:disabled:hover,
  .fr-header__menu-links-custom a.fr-link:not([href]):hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .fr-header__menu-links-custom .fr-link:disabled:active,
  .fr-header__menu-links-custom a.fr-link:not([href]):active {
    background-color: rgba(0, 0, 0, 0.1);
  }

.fr-header .fr-header__menu-links-custom::after {
  display: block;
  margin: 0.75rem -1rem;
  width: calc(100% + 2rem);
  height: 1px;
}

@media (min-width: 62em) {
    .fr-header__menu-links-custom {
        display: none;
    }
}

.ql-container {
    border: none !important;
    height: auto !important;
    display: block;
    width: 100%;
    border-radius: .25rem .25rem 0 0;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: .5rem 1rem;
    color: #161616;
    color: var(--text-label-grey);
    background-color: #eee;
    background-color: var(--background-contrast-grey);
    --idle: transparent;
    --hover: var(--background-contrast-grey-hover);
    --active: var(--background-contrast-grey-active);
    box-shadow: inset 0 -2px #3a3a3a;
    box-shadow: inset 0 -2px 0 0 var(--border-plain-grey);
}

format-input.quill-error div.ql-container {
    box-shadow: inset 0 -2px 0 0 var(--border-plain-error) !important;
}
